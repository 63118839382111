<template>
    <div class="view pa24">
        <div class="selectCompany flex-a-c hover_pointer" @click="showCompanyList"><i
                class="el-icon-office-building fs12 mr5"></i> <span class="fs13">{{ checkedCompany.companyName }}</span>
            <i class="el-icon-caret-bottom  fs12 ml5"></i>
        </div>
        <div>
            <el-tabs v-model="year" @tab-click="searchFun">
                <el-tab-pane label="全部" name="all"></el-tab-pane>
                <el-tab-pane :label="String(y) + '年'" :name="String(y)" v-for="(y, index) in yearData"
                    :key="index"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="d-flex mt20">
            <div>
                <el-input class="w400 mr10" placeholder="请输入知识产权名称" v-model="searchData.name" @change="searchFun" />
                <!-- <el-button type="primary" @click="searchFun">搜索</el-button> -->
            </div>
            <div style="margin-left: auto">
                <!-- <el-button class="ml10 mr10" type="primary" :disabled="!checkedCompany.pcId"
                    @click="showImport = true">草稿箱</el-button> -->
                <!-- <el-button class="ml10 mr10" type="primary" :disabled="!checkedCompany.pcId"
                    @click="showImport = true">导入知识产权</el-button> -->
                <el-button class="ml10 mr10" type="primary" :disabled="!checkedCompany.pcId"
                    @click="showAddModel">新增知识产权</el-button>
            </div>
        </div>
        <!--  class="tableRadio" -->
        <commonTable :tableData="tableData" :pageSize="50" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column prop="number" align="center" label="编号" width="80" />
                <el-table-column prop="propertyName" label="知识产权名称" show-overflow-tooltip min-width="150" />
                <el-table-column prop="money" align="center" label="类别" show-overflow-tooltip width="150">
                    <template slot-scope="scope">
                        {{ propertyTypeObj[scope.row.type] }}
                    </template>
                </el-table-column>
                <el-table-column prop="propertyUser" align="center" label="专利人" show-overflow-tooltip width="150" />
                <el-table-column prop="authorizeDay" align="center" label="授权日期" show-overflow-tooltip width="120">
                    <template slot-scope="scope">
                        {{ scope.row.authorizeDay ? scope.row.authorizeDay.slice(0, 10) : '-' }}
                    </template>
                </el-table-column>
                <el-table-column prop="code" align="center" label="授权号" show-overflow-tooltip width="180">
                </el-table-column>
                <el-table-column prop="way" align="center" label="获得方式" show-overflow-tooltip width="100">
                    <template slot-scope="scope">
                        {{ wayTypeObj[scope.row.way] }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button type="text" @click.stop="showUpdateModel(scope.row)">编辑</el-button>
                        <el-button style="color:#F56C6C" type="text" @click.stop="delProperty(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>

        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle"
            :visible.sync="showModel" :destroy-on-close="true" width="80%" center>
            <!-- <el-button class="ml10 mr10" type="primary"
                @click="showImport = true">扫描证书</el-button> -->
            <el-form :model="formData" :rules="rules" ref="formData" label-width="100px">
                <el-row>
                    <el-col :md="12">
                        <el-form-item label="编号" prop="number">
                            <el-input v-model="formData.number" placeholder="请输入编号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="12">
                        <el-form-item label="授权号" prop="code">
                            <el-input v-model="formData.code" placeholder="请输入授权号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="12">
                        <el-form-item label="知识产权名称" prop="propertyName">
                            <el-input v-model="formData.propertyName" placeholder="请输入知识产权名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="12">
                        <el-form-item label="类型" prop="type">
                            <el-select class="w100p" v-model="formData.type" clearable placeholder="请选择类型">
                                <el-option v-for="(name, id) in propertyTypeObj" :key="id" :label="name"
                                    :value="String(id)">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="12">
                        <el-form-item label="获得方式" prop="way">
                            <el-select class="w100p" v-model="formData.way" clearable placeholder="请选择获得方式">
                                <el-option v-for="(name, id) in wayTypeObj" :key="id" :label="name" :value="String(id)">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="12">
                        <el-form-item label="专利人" prop="propertyUser">
                            <el-input v-model="formData.propertyUser" placeholder="请输入专利人"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="12">
                        <el-form-item label="授权日期" prop="authorizeDay">
                            <el-date-picker type="date" placeholder="选择授权日期" value-format="yyyy-MM-dd"
                                v-model="formData.authorizeDay" style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="24">
                        <el-form-item label="技术关键词" prop="keyWord">
                            <el-input placeholder="知识产权核心技术关键词" maxlength="200" show-word-limit
                                v-model="formData.keyWord"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="24">
                        <el-form-item label="摘要" prop="remark">
                            <el-input placeholder="国家知识产权局官方网站上公布的摘要(限400字)" type="textarea" rows="5" show-word-limit
                                maxlength="1000" v-model="formData.remark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="24">
                        <el-form-item label="说明" prop="description">
                            <el-input placeholder="该知识产权的先进性说明，及对本企业主营产品(服务)核心技术的支持作用说明(限400字)" type="textarea" rows="5"
                                show-word-limit maxlength="1000" v-model="formData.description"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="submitData" :loading="btnLoading">提 交</el-button>
            </span>
        </el-dialog>
        <select-company-list ref="selectCompanyList" @confirmFun="selectCompany"></select-company-list>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import selectCompanyList from "@/pages/projectAdmin/components/selectCompanyList";
import { getProjectPropertyList, setProjectProperty, getProjectPropertyTypeNum } from "@/api/projectAdmin";
export default {
    name: "projectAdmin",
    components: {
        commonTable,
        selectCompanyList,
    },
    data() {
        return {
            checkedCompany: {},
            //列表配置
            yearData: [],
            year: "all",
            currentPage: 1, //当前页
            pageSize: 50, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            //模态框配置
            modelTitle: "",
            formData: {
                number: "IP",
                propertyName: "",
                code: "",
                keyWord: "",
                authorizeDay: "",
                description: "",
                remark: "",
                type: "",
                way: "",
                propertyUser: "",
                description: "",
            },
            // dayValue: null,
            userLoading: false,
            rules: {
                projectNo: {
                    required: true,
                    trigger: "blur",
                    message: "请输入项目序号",
                },
                departmentId: {
                    required: true,
                    message: '请选择所属部门',
                    trigger: "change",
                },
            },
            formType: "",
            updateId: 0,
            wayTypeObj: this.$store.state.wayTypeObj,
            propertyTypeObj: this.$store.state.propertyTypeObj,
            checkedProject: {},
            btnLoading: false,
        };
    },
    mounted() {
        let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
        if (!checkedCompany) {
            this.showCompanyList();
        }
        this.checkedCompany = checkedCompany ? checkedCompany : { companyName: "请选择研发企业" };
        //获取年份
        this.getYearData();
        //获取项目列表
        this.getList();
    },
    methods: {
        showCompanyList() {
            this.$refs.selectCompanyList.showModelFun();
        },
        selectCompany(row) {
            this.checkedCompany = row;
            sessionStorage.setItem("checkedProjectCompany", JSON.stringify(row));
            this.searchData.name = "";
            this.searchFun();
        },
        /**@method 删除项目 */
        delProperty(row) {
            this.$confirm("确定要继续删除这条研发项目", "删除研发项目", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                let params = {
                    isDel: 1,
                    ppId: row.ppId
                }
                setProjectProperty(params).then(res => {
                    if (res.code === 200) {
                        this.$message.success('删除完成');
                        this.showModel = false;
                        this.searchData.name = "";
                        this.searchFun();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    if (err.code === 201) {
                        this.$message.error(err.message);
                    }
                })
            })
        },
        /**@method 获取最近五年 */
        getYearData() {
            let yearData=[];
let F = new Date().getFullYear();
            for (let i = 0; i <= 4; i++) {
                yearData.push(F - i);
            }
this. yearData= yearData;
        },
        /**@method 搜索 */
        searchFun() {
            this.currentPage = 1;
            this.getList();
        },
        /**@method 提交新增/修改数据 */
        submitData() {
            //修改添加会员等级
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    let params = {
                        ...this.formData,
                        pcId: this.checkedCompany.pcId,
                    }
                    let message = "新增完成";
                    if (this.formType === 'update') {
                        params.ppId = this.updateId;
                        message = "编辑完成"
                    }
                    setProjectProperty(params).then(res => {
                        this.btnLoading = false;
                        if (res.code === 200) {
                            this.$message.success(message);
                            this.showModel = false;
                            this.getList();
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        this.btnLoading = false;
                        if (err.code === 201) {
                            this.$message.error(err.message);
                        }
                    })
                }
            });
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                projectName: this.searchData.name,
                sortCode: 1
            };
            if (this.year != 'all') {
                params.selectYear = this.year
            }
            if (!this.checkedCompany.pcId) {
                this.tableData = [];
                this.total = 0
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            try {
                this.loading = true;

                let result = await getProjectPropertyList(params)
                const { data } = result;
                this.loading = false;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增知识产权";
            this.formType = "add";
            this.formData = {
                number: "IP",
                propertyName: "",
                code: "",
                keyWord: "",
                authorizeDay: "",
                description: "",
                remark: "",
                type: "",
                way: "",
                propertyUser: this.checkedCompany.companyName,
                description: "",
            };
            if (this.year != 'all') {
                let n = this.total + 1;
                this.formData.number = n < 10 ? "IP0" + n : "IP" + n;
            } else {
                this.formData.number = "IP"
            }
            // this.dayValue = null;
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} row - 当前点击行的值
         */
        showUpdateModel(row) {
            this.showModel = true;
            this.modelTitle = "编辑项目";
            this.formType = "update";
            this.updateId = row.ppId;
            // this.dayValue = [row.startDay, row.endDay];
            this.formData = {
                number: "IP",
                propertyName: data.propertyName,
                code: data.code,
                keyWord: data.keyWord,
                authorizeDay: data.authorizeDay,
                description: data.description,
                remark: data.remark,
                type: data.type,
                way: data.way,
                propertyUser: data.propertyUser,
                description: data.description,
            };

        },
    },
};
</script>

<style lang="scss" scoped>
.selectCompany {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #51CBCD;
    cursor: pointer;
}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>